import React from 'react';
import PropTypes from 'prop-types';
// import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Faq from '../components/organisms/FAQ/Faq';
import Layout from '../components/organisms/Layout/Layout';
import Container from '../components/atoms/Container/Container';
import Breadcrumbs from '../components/atoms/Breadcrumbs/Breadcrumbs';
import Seo from '../components/organisms/Seo/Seo';
import FaqChatWithUs from '../components/molecules/FaqChatWithUs/FaqChatWithUs';

const ContentfulFaqs = ({ data }) => {

    const page = (data && data.faqs.edges[0].node) || false;

    return (
        <Layout>
            <Seo title={page.name} />
            <Container size="medium">
                <Breadcrumbs crumbs={[{link: '/', label:'Home'}, {link:'/faqs/', label:'FAQs'}, {label:`${page.name}`}]}/>
                
                <h1 className='h4 mb-5 purple'>{page.name}</h1>
                <Faq data={page.faqItems} categorySlug={page.slug.replace(/^\/|\/$/g, '')}/>
            </Container>
            <FaqChatWithUs background="true" />
        </Layout>
    );
};

ContentfulFaqs.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
};

export default ContentfulFaqs;

export const query = graphql`
    query ContentfulFaqCategories($id: String!) {
        faqs: allContentfulFaqCategories(filter: {id: {eq: $id}}) {
            edges {
                node {
                  slug
                  name
                  faqItems {
                    name
                    content {
                        raw
                        references {
                        ... on ContentfulAsset {
                            contentful_id
                            title
                            file {
                            url
                            fileName
                            details {
                                size
                            }
                            }
                            id
                        }
                        }
                    }
                    updatedAt(formatString: "MMMM DD, YYYY")
                  }
                }
            }
        }
    }
`;
