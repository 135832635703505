import React from 'react'
// import { useStaticQuery, graphql } from 'gatsby';

import * as styles from './Faq.module.css'
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import Icon from '../../atoms/Icon/Icon';
import FaqContent from '../FaqContent/FaqContent';

const Faq = ({data, categorySlug}) => {
    const faqData = data;

    return (  
        <div className={styles.mainBody}>
            {faqData?.map((item, id) => (
                <Accordion className={styles.faqAccordionItem}>
                    <AccordionSummary expandIcon={<Icon symbol="arrowRightPurple" />}>
                        {item.name}
                    </AccordionSummary>
                    <AccordionDetails>
                        <FaqContent post={item} />
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
}
 
export default Faq;