import React from 'react';
import * as styles from './FaqChatWithUs.module.css';
import Container from '../../atoms/Container/Container';
const FaqBlock = ({ background }) => {
  
  return (
    <div className={`${styles.chatWrapper} ${background? styles.background: 'default'}`}>
        <Container size="medium">
            <div>
                <h4 className={`${styles.h4Purple}`}>Still have questions?</h4>
                <h4 className={`${styles.h4Black}`}>Let's connect.</h4>
                <p>We love connecting with people curious about green energy.</p>
                <a className={`btn btn-cta ${styles.chatButton}`} href="/contact-us">Get In touch</a>
            </div>
        </Container>
    </div>
  );
};

export default FaqBlock;
