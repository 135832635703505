import React from 'react';
import PropTypes from 'prop-types';
// import Container from '../../atoms/Container/Container';
// import SocialShare from '../../molecules/SocialShare/SocialShare';
// import { readTime } from '../../../helpers/general';
import * as styles from './FaqContent.module.css';
// import ContentfulContext from '../../../context/ContentfulProvider';
// import Breadcrumbs from '../../atoms/Breadcrumbs/Breadcrumbs';

const FaqContent = ({ post }) => {
    // const title = post.name;
    // const slug = post.slug;
    const contentAsset = post.content.references;
    // const date = post.updatedAt;
    const content = post.content.raw;

    let beautifiedContent = '';
    JSON.parse(content).content.forEach(e => {
        // console.log(e);
        switch (true) {
            case e.nodeType === 'paragraph':
                return beautifiedContent += `<p>${e.content[0].value}</p>`;
            case e.nodeType === "unordered-list":
                // console.log(e);
                beautifiedContent += `<ul>`;
                e.content.forEach(list => {
                    // console.log(list)
                    beautifiedContent += `<li>${list.content[0].content[0].value}</li>`;
                })
                beautifiedContent += `</ul>`;
                return beautifiedContent;
                // return beautifiedContent += `<p>${e.content[0].value}</p>`;
            case e.nodeType === "embedded-asset-block":
                let embededUrl = contentAsset.filter(x => x.contentful_id === e.data.target.sys.id).map(x => x.file.url);
                return beautifiedContent += `<div><img src="${embededUrl}"></div>`;
            default:
                return '';
        }
    });

    return (
        <>
            <div>
                <div className={styles.postBody}>
                    {/* <div className={styles.socials}>
                        <SocialShare imageUrl={thumbnailUrl} url={`${process.env.SITE_URL}${customizedSlug}`} text={title}/>
                    </div> */}
                    {content && (
                        <div
                            className={styles.postContent}
                            dangerouslySetInnerHTML={{
                            __html: beautifiedContent
                            }}
                        />
                    )}
                </div>
                
            </div>
        </>
    );
};

FaqContent.propTypes = {
  post: PropTypes.shape({}).isRequired
};

export default FaqContent;
